export default {
  text: 'black',
  primary: '#027d8a',
  secondary: '#f01295',
  tertiary: '#f8f908',
  quaternary: '#f5951e',
  background: '#f2f0e3',
  backgroundSecondary: '#33cbc9',
  light: '#FFF',
  dark: '#020303'
}
